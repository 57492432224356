import { InfoObject } from '@components/InfoObject';
import {
    getGenericLabel,
    getTypeByModel,
    isEmpty,
    getModelIcon,
    getTranslatedPluralTitle,
    isModelSupported,
} from '@form-configs';
import { InfoToolResult } from '@generated';
import {
    ToggleObject,
    ToggleObjectItem,
    ToggleObjectProps,
    useIsMobile,
} from '@keypro/2nd-xp';
import { useMobileMenu, useRightMenu } from '@stores';

/**
 * The ToggleObjectController component props.
 */
export interface ToggleObjectControllerProps extends ToggleObjectProps {
    model: string;
    value?: InfoToolResult[];
}

/**
 * The ToggleObjectController component displays object nearby of the map.
 * @returns The ToggleObjectController component
 */
export const ToggleObjectController = ({
    model,
    shouldExpandable,
    isExpandedByDefault,
    value,
}: ToggleObjectControllerProps) => {
    const isMobile = useIsMobile();
    const menuContext = isMobile ? useMobileMenu : useRightMenu;
    const { setMenuContent } = menuContext();
    const title = isModelSupported(model)
        ? getTranslatedPluralTitle(getTypeByModel(model))
        : model;

    return (
        <ToggleObject
            title={title}
            shouldExpandable={shouldExpandable}
            isExpandedByDefault={isExpandedByDefault}
            quantity={value?.length}
        >
            {value?.map((result: InfoToolResult) => {
                return (
                    <ToggleObjectItem
                        icon={getModelIcon(model)}
                        text={
                            isEmpty(result.identification)
                                ? getGenericLabel(result.model, result.pk!)
                                : result.identification
                        }
                        key={result.pk}
                        onClickAction={() => {
                            setMenuContent(
                                `InfoObject-${result.model}-${result.pk}`,
                                <InfoObject
                                    model={result.model!}
                                    id={result.pk!}
                                />,
                            );
                        }}
                    />
                );
            })}
        </ToggleObject>
    );
};
