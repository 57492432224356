import { getSewerDucts } from '@apis/keyduct';
import { FormConfig } from '../types';
import { ductGroups } from './groups';
import { SewerDuctIcon } from './icons';
import { createComboField } from '../field-utils';

const config: FormConfig = {
    gqlType: 'SewerDuct',
    model: 'snsewerduct',
    modelAliases: ['snsewerductinfo'],
    icon: <SewerDuctIcon />,
    groups: [
        {
            name: 'generalInformation',
            fields: [
                createComboField('type.netType', 'NET_TYPE'),
                createComboField('ductType.networkType', 'NETWORK_TYPE'),
                createComboField('ductType.ductType', 'DUCT_TYPE'),
            ],
        },
        ...ductGroups,
    ],
    functions: {
        get: getSewerDucts,
    },
    queryCollection: 'sewerDucts',
};

export default config;
