import {
    NavigationBar,
    NavigationButton,
    Icons,
    Button,
    styled,
    useIsMobile,
} from '@keypro/2nd-xp';
import { useLeftMenu, useMobileMenu } from '@stores';
import { t } from 'i18next';
import { LayersMenu } from './Layers/LayersMenu';
import { MapViewMenu } from './MapViews/MapViewMenu';
import { ToolsMenu } from './Tools/ToolsMenu';
import { useEffect } from 'react';
import { DummyLeftMenu } from './NavigationBarCommon';

const DetailedBtn = styled(Button)`
    width: calc(100% - 16px);
    justify-content: flex-start;
    height: 40px;
    border-radius: 6px;
    --active-color: ${(props) => props.theme.colors.accents.blue['50']};

    &.active,
    &:active {
        color: var(--active-color) !important;
        background-color: ${(props) => props.theme.colors.neutral['50']} !important;
        & path[fill], & g[fill] {
            fill: var(--active-color);
        }
        & path[stroke], & circle {
            stroke: var(--active-color);
        }
`;

const DefaultdBtn = styled(NavigationButton)`
    height: 40px;
    width: 40px;
    border-radius: 6px;

    &.active,
    &:active {
        &:before {
            height: 40px;
            margin-top: 0;
            top: 0;
        }
`;

const DetailedNavigationBar = styled(NavigationBar)`
    width: 200px;
`;

const AppNavigationBar = (): JSX.Element => {
    const isMobile = useIsMobile();
    const menuContext = isMobile ? useMobileMenu : useLeftMenu;
    const {
        setMenuContent,
        toggleMenu,
        menuContentId,
        isMenuOpen,
        detailedMenu,
        setDetailedMenu,
    } = menuContext();
    const { setMenuHeight } = useMobileMenu();

    useEffect(() => {
        isMenuOpen && setDetailedMenu(false);
    }, [menuContentId, isMenuOpen, setDetailedMenu]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                !(
                    document
                        .getElementById('navigation-bar')
                        ?.contains(event.target as Node) ||
                    document
                        .getElementById('app-toolbar')
                        ?.contains(event.target as Node)
                )
            ) {
                setDetailedMenu(false);
            }
        };

        if (detailedMenu) {
            document.addEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [detailedMenu, setDetailedMenu]);

    // just a temporary function to set the active class on the navigation buttons
    // until each button has actually been implemented
    const getClassName = (label: string): string => {
        return menuContentId === label && isMenuOpen ? 'active' : '';
    };

    const buttonConfig = [
        {
            label: t('network'),
            icon: <Icons.Net />,
            contentId: 'Network',
            content: <DummyLeftMenu />,
            testId: 'navigationbar-network',
            tooltip: t('network'),
        },
        {
            label: t('layers'),
            icon: <Icons.Layers />,
            contentId: 'Layers',
            content: <LayersMenu />,
            testId: 'navigationbar-layers',
            tooltip: t('layers'),
        },
        {
            label: t('saved'),
            icon: <Icons.BookmarkSave />,
            contentId: 'Saved',
            content: <DummyLeftMenu />,
            testId: 'navigationbar-saved',
            tooltip: t('saved'),
        },
        {
            label: t('create'),
            icon: <Icons.Create />,
            contentId: 'Create',
            content: <DummyLeftMenu />,
            testId: 'navigationbar-create',
            tooltip: t('create'),
        },
        {
            label: t('views'),
            icon: <Icons.MapView />,
            contentId: 'views',
            content: <MapViewMenu />,
            testId: 'navigationbar-views',
            tooltip: t('views'),
        },
        {
            label: t('tools'),
            icon: <Icons.Tools />,
            contentId: 'Tools',
            content: <ToolsMenu />,
            testId: 'navigationbar-tools',
            tooltip: t('tools'),
        },
    ];

    const ButtonComponent = detailedMenu ? DetailedBtn : DefaultdBtn;

    const renderButton = ({
        label,
        icon,
        contentId,
        content,
        testId,
        tooltip,
    }: (typeof buttonConfig)[0]) => {
        return (
            <ButtonComponent
                key={testId}
                data-testid={testId}
                iconPosition={detailedMenu ? 'left' : undefined}
                kind="ghost"
                label={detailedMenu ? label : undefined}
                className={getClassName(contentId)}
                onClick={() => {
                    setMenuContent(contentId, content);
                    isMobile && setMenuHeight(50);
                }}
                data-tooltip-right={tooltip}
            >
                {icon}
            </ButtonComponent>
        );
    };

    const MoreButton = (
        <ButtonComponent
            iconPosition={detailedMenu ? 'left' : undefined}
            kind="ghost"
            data-testid="navigationbar-more"
            label={detailedMenu ? t('more') : undefined}
            data-tooltip-right={t('moreTooltip')}
            onClick={() => toggleMenu()}
        >
            <Icons.MoreHollow />
        </ButtonComponent>
    );

    const NavBarContainer = detailedMenu
        ? DetailedNavigationBar
        : NavigationBar;

    return isMobile && !detailedMenu ? (
        <></>
    ) : (
        <NavBarContainer
            id="navigation-bar"
            data-testid={`navigationbar-${detailedMenu ? 'detailed' : 'default'}`}
        >
            {buttonConfig.map((config) => renderButton(config))}
            {MoreButton}
        </NavBarContainer>
    );
};

export default AppNavigationBar;
