import { Toolbar, ToolbarGroup, Button, Icons, styled } from '@keypro/2nd-xp';
import { t } from 'i18next';
import { useMobileMenu, useSearchStore } from '@stores';
import { SearchFilters } from '@components/Search/search-components/SearchFilters';
import SearchField from '@components/Search/SearchField';
import { AdvancedSearch } from '@components/Search';
import { useRef } from 'react';

/**
 * Custom toolbar for mobile mode, e.g., it can include
 * additional SearchFilter or an attached advancedSearch option.
 */
const MobileDetailedToolbar = (): JSX.Element => {
    const {
        toggleMenu: toggleMobileMenu,
        setIsDetailedToolbar,
        restorePreviousMenu,
        menuOverlayContent,
        setMenuOverlayContent,
    } = useMobileMenu();
    const {
        isAdvancedSearchOpen,
        setIsAdvancedSearchOpen,
        setIsFullsearch,
        locationFilter,
        setLocationFilter,
    } = useSearchStore();

    const handleCloseAdvancedSearch = () => {
        restorePreviousMenu();
        setMenuOverlayContent(null);
        if (locationFilter) {
            locationFilter.clearIfLayerExist();
            setLocationFilter(null);
        } else {
            setIsAdvancedSearchOpen(false);
        }
    };

    const renderToolBar = () => {
        let content = (
            <>
                <ToolbarGroup key={'toolbar-backBtn'}>
                    <StyledBackBtn>
                        <Button
                            kind="ghost"
                            data-testid="toolbar-backBtn"
                            key={'toolbar-backBtn'}
                            onClick={() => {
                                setIsDetailedToolbar(false);
                                setIsFullsearch(false);
                                toggleMobileMenu();
                            }}
                        >
                            <Icons.Arrow />
                        </Button>
                    </StyledBackBtn>
                </ToolbarGroup>
                <ToolbarGroup
                    key={'toolbar-searchField'}
                    style={{ flexGrow: 2 }}
                >
                    <StyledSearchField data-testid="toolbar-searchField" />
                </ToolbarGroup>
            </>
        );

        if (isAdvancedSearchOpen) {
            content = (
                <StyledHeader>
                    {t('filters')}
                    <StyledCloseButton
                        kind="ghost"
                        onClick={handleCloseAdvancedSearch}
                        data-testid="navigationbar-menu-closeBtn"
                    >
                        <Icons.Cross />
                    </StyledCloseButton>
                </StyledHeader>
            );
        }
        return content;
    };
    const advancedSearchRef = useRef<{
        handleSubmit: (e: React.FormEvent, newFilters?: string[]) => void;
    }>(null);

    if (menuOverlayContent !== null)
        return (
            <StyledDetailedToolBar data-testid="app-detailed-toolbar">
                <Toolbar data-testid="app-toolbar" id="app-toolbar">
                    <ToolbarGroup key={'toolbar-backBtn'}>
                        <StyledBackBtn>
                            <Button
                                kind="ghost"
                                data-testid="toolbar-backBtn"
                                key={'toolbar-backBtn'}
                                onClick={() => {
                                    if (locationFilter) {
                                        locationFilter.clearIfLayerExist();
                                        setLocationFilter(null);
                                    }
                                    useMobileMenu.setState(() => ({
                                        menuContent: (
                                            <AdvancedSearch
                                                ref={advancedSearchRef}
                                                onLoading={() => {}}
                                                data-testid="advanced-search"
                                            />
                                        ),
                                        menuContentId: 'AdvancedSearch-mobile',
                                        menuHeight: 100,
                                        menuOverlayContent: null,
                                    }));
                                }}
                            >
                                <Icons.Arrow />
                            </Button>
                        </StyledBackBtn>
                    </ToolbarGroup>
                    <ToolbarGroup
                        key={'toolbar-locationFilter'}
                        style={{
                            flexGrow: 2,
                            justifyContent: 'center',
                        }}
                    >
                        <StyledTitleAndHint data-testid="app-detailed-toolbar-titleAndHint">
                            <h4>{t('selectLocation')}</h4>
                            {t('selectLocationHint')}
                        </StyledTitleAndHint>
                    </ToolbarGroup>
                </Toolbar>
            </StyledDetailedToolBar>
        );

    return (
        <StyledDetailedToolBar id="app-detailed-toolbar">
            <Toolbar data-testid="app-toolbar" id="app-toolbar">
                {renderToolBar()}
            </Toolbar>
            <SearchFilters />
        </StyledDetailedToolBar>
    );
};

export default MobileDetailedToolbar;

const StyledBackBtn = styled.div`
    & > button {
        color: ${(props) => props.theme.colors.neutral['90']};
        & > svg {
            width: 24px;
            height: 24px;
            transform: rotate(90deg);
            background-color: ${(props) => props.theme.colors.neutral['50']};
            border-radius: 50%;
        }
    }
`;

const StyledSearchField = styled(SearchField)`
    & > div,
    & > div > input {
        width: 100%;
    }
`;

const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: ${({ theme }) => theme.fonts['16px Bold']};
    font-weight: 700;
    max-height: 32px;
    padding-left: 4px;
    width: 100%;
    & > svg {
        width: 13px;
        height: 13px;
    }
`;

const StyledCloseButton = styled(Button)`
    width: 48px;
    height: 48px;
    & > svg {
        width: 18px;
        height: 18px;
        & path {
            stroke: ${({ theme }) => theme.colors.neutral['90']};
        }
    }
`;

const StyledDetailedToolBar = styled.div``;

const StyledTitleAndHint = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${(props) => props.theme.fonts['12px Regular']}
    & > h4 {
        ${(props) => props.theme.fonts['14px Bold']}
        margin: 0;
    }
`;
