import { Icons, styled } from '@keypro/2nd-xp';

export const SewerDuctIcon = styled(Icons.Duct)`
    path {
        stroke: #b35a37;
    }
`;

export const WaterDuctIcon = styled(Icons.Duct)`
    path {
        stroke: #6f90f2;
    }
`;
