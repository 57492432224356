import { create } from 'zustand';
import { getSnConstants } from '@apis/keyduct';
import { SnConstant } from '@generated';

interface SnConstantState {
    snConstants: SnConstant[];
    isLoading: boolean;
    fetchSnConstants: (limit?: number) => Promise<void>;
    getByGroupName: (groupname: string) => SnConstant[];
}

export const useSnConstants = create<SnConstantState>((set, get) => ({
    snConstants: [],
    isLoading: false,
    error: null,
    fetchSnConstants: async () => {
        set({ isLoading: true });
        try {
            const snConstants = await getSnConstants(undefined, 20000);
            set({ snConstants, isLoading: false });
        } catch (error) {
            set({ isLoading: false });
            throw error;
        }
    },
    getByGroupName: (groupname: string) => {
        const { snConstants } = get();
        return snConstants.filter(
            (snConstant) => snConstant.groupname === groupname,
        );
    },
}));
