import { styled, ToolCard, Icons } from '@keypro/2nd-xp';
import { t } from 'i18next';
import { ResponsiveSideMenu } from '../NavigationBarCommon';

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.neutral[30]};
`;

// NOTE: Should be replaced with the actual tool
const NodeDuctsAndElevationsTool = (): JSX.Element => (
    <ToolCard
        title={t('nodeDuctsAndElevations')}
        description={t('NodeDuctsElevationsDesc')}
        image={<Icons.NodeDuctsElevations />}
        imageBgColor="#EBAF1E"
        onClick={() => alert('tool clicked')}
    />
);

/**
 * Tools Menu that displays the list of tools
 * @returns
 */
export const ToolsMenu = (): JSX.Element => {
    /**
     * Initial hardcoded "tools", that should later be replaced with
     * actual logic to display real tools based on configurations
     */
    return (
        <ResponsiveSideMenu title={t('tools')}>
            <NodeDuctsAndElevationsTool />
            <Divider />
            <NodeDuctsAndElevationsTool />
            <Divider />
            <NodeDuctsAndElevationsTool />
            <Divider />
            <NodeDuctsAndElevationsTool />
            <Divider />
            <NodeDuctsAndElevationsTool />
            <Divider />
            <NodeDuctsAndElevationsTool />
        </ResponsiveSideMenu>
    );
};
