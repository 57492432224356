import {
    FormBuilder,
    getComponentType,
    getData,
} from '@components/FormBuilder';
import {
    FormData as FormConfigData,
    getTypeByModel,
    isModelSupported,
    getFormConfig,
    getTranslationKey,
    getLabel,
    FormFields,
} from '@form-configs';
import {
    Button,
    Icons,
    Select,
    SelectOptionItem,
    styled,
    useIsMobile,
} from '@keypro/2nd-xp';
import {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { t } from 'i18next';
import { GraphQLFilter } from '@apis/utils';
import { SearchArea, SearchResult } from '@generated';
import {
    getFilteredConfig,
    getType,
    infoToolLayers,
    objectTypes,
} from '@components/utils';
import { fetchInfoToolResults } from '@apis/map';
import { useFormBuilder, useSearchStore, useRecentObjects } from '@stores';
import { LocationFilter } from './search-components/LocationFilter';

export type FilterObject = {
    [key: string]: unknown; // Adjust the type as necessary
};

/**
 * Props for the AdvancedSearch component
 */
interface AdvancedSearchProps {
    /** Should the search be done from recent objects */
    searchFromRecent?: boolean;
    /** Function to handle loading state */
    onLoading: (loading: boolean) => void;
    /** Search results to limit the search to */
    searchResults?: SearchResult[];
}

/**
 * Advanced search component that allows users to search for objects based on selected groups
 * @param isOpen - The state of the tool
 * @returns JSX.Element
 */
export const AdvancedSearch = forwardRef(
    (
        {
            searchFromRecent = false,
            onLoading,
            searchResults,
        }: AdvancedSearchProps,
        ref,
    ) => {
        const { types } = useFormBuilder();
        const [data, setData] = useState<FormConfigData | undefined>(undefined);
        const [type, setType] = useState<string | null>(null);
        const { getRecentObjectsByModel } = useRecentObjects();
        const isMobile = useIsMobile();
        const {
            isAdvancedSearchOpen: isOpen,
            setIsAdvancedSearchOpen,
            setIsUsingAdvancedSearch,
            selectedAdvancedFilters,
            setSelectedAdvancedFilters,
            selectedObjectType,
            setSelectedObjectType,
            setDataAdvancedSearch,
            selectedGroups,
            setSelectedGroups,
            locationFilter,
            setLocationFilter,
            setModelAdvancedSearch,
            setQuickFilterComponent,
        } = useSearchStore();
        const advancedSearchRef = useRef<HTMLDivElement>(null);

        const locationFilterActive = useMemo(() => {
            return selectedGroups.includes('location');
        }, [selectedGroups]);

        useImperativeHandle(ref, () => ({
            handleSubmit,
        }));

        const getFieldType = useCallback(
            (fieldName: string) => {
                return getComponentType(
                    types[getTypeByModel(selectedObjectType)].fields.find(
                        (f) => f.name === fieldName,
                    ),
                );
            },
            [types, selectedObjectType],
        );

        // Options for the select input, memoized to optimize rendering
        const options: SelectOptionItem[] = useMemo(() => {
            if (isModelSupported(selectedObjectType)) {
                const gqlType = getTypeByModel(selectedObjectType);
                setType(gqlType);
                if (types[gqlType]) {
                    const groupsForSearch: FormFields = [];
                    const form = getFormConfig(gqlType);
                    form.groups.forEach((group) => {
                        group.fields.forEach((field) => {
                            if (
                                typeof field === 'string' &&
                                !form.excludeFromSearch?.includes(field)
                            ) {
                                groupsForSearch.push(field);
                            } else if (
                                typeof field !== 'string' &&
                                !form.excludeFromSearch?.includes(field.name)
                            ) {
                                groupsForSearch.push(field.name);
                            }
                        });
                    });
                    groupsForSearch?.push('location');
                    return types[gqlType].fields
                        .map((field) => {
                            if (groupsForSearch.includes(field.name)) {
                                const translationKey = getTranslationKey(
                                    gqlType,
                                    field.name,
                                );

                                const label = t(translationKey);

                                if (label) {
                                    return {
                                        value: field.name,
                                        label: t(translationKey),
                                    };
                                }
                            }
                        })
                        .filter(
                            (option): option is SelectOptionItem =>
                                option !== undefined,
                        );
                }
            }
            return [];
        }, [types, selectedObjectType]);

        // Effect to handle model change and set selected groups
        useEffect(() => {
            if (isModelSupported(selectedObjectType)) {
                const gqlType = getTypeByModel(selectedObjectType);
                setType(gqlType);
                const formConfig = getFormConfig(gqlType);
                // Initialize selected groups with all available groups
                setSelectedGroups(formConfig.groups.map((group) => group.name));
            }
        }, [selectedObjectType, setSelectedGroups]); // Depend only on model

        useEffect(() => {
            if (selectedAdvancedFilters) {
                setSelectedGroups(Object.keys(selectedAdvancedFilters));
            }
        }, [selectedAdvancedFilters, setSelectedGroups]);

        // Effect to fetch data based on the selected type
        useEffect(() => {
            if (type) {
                setData(undefined);
                getData(type, {}).then((results) => {
                    if (results?.length > 0) {
                        setData(results[0]);
                    }
                });
            }
        }, [type]); // Depend only on type

        // Filtered configuration for form fields based on selected groups
        const filteredConfig = useMemo(
            () =>
                getFilteredConfig(
                    selectedObjectType,
                    selectedGroups,
                    type,
                    getFieldType,
                ),
            [selectedObjectType, selectedGroups, type, getFieldType],
        );

        const handleSubmit = async (
            e: React.FormEvent,
            newFilters?: string[],
        ) => {
            e.preventDefault();
            submitFunction(newFilters);
        };

        const submitFunction = useCallback(
            async (newFilters?: string[], customData?: FormConfigData) => {
                const handleEqOperator = (group: string, value: unknown) => {
                    return group === 'location' ? { eq: '' } : { eq: value };
                };

                onLoading(true);
                setIsAdvancedSearchOpen(false);

                const filter: FilterObject = {
                    id: {
                        in: [],
                    },
                };

                const tempData = customData ?? data;
                (newFilters ?? selectedGroups).forEach((group) => {
                    if (tempData?.[group] !== undefined) {
                        const value = tempData[group];

                        if (Array.isArray(value)) {
                            filter[group] = {
                                id: {
                                    in: value,
                                },
                            };
                        } else if (
                            typeof value === 'object' &&
                            group !== 'location'
                        ) {
                            if (value) {
                                const max = (value as { max: number }).max;
                                if (max) {
                                    filter[group] = {
                                        gte: (value as { min: number }).min,
                                        lte: (value as { max: number }).max,
                                    };
                                } else {
                                    filter[group] = {
                                        gte: (value as { min: number }).min,
                                    };
                                }
                            } else {
                                filter[group] = {
                                    gte: 0,
                                };
                            }
                        } else {
                            filter[group] = handleEqOperator(group, value);
                        }
                    }
                });

                if (searchResults) {
                    const ids = searchResults
                        .filter(
                            (result) => result.modelName === selectedObjectType,
                        )
                        .map((object) => object.id);
                    filter.id = {
                        in: ids,
                    };
                }

                if (searchFromRecent) {
                    const recentObjects =
                        getRecentObjectsByModel(selectedObjectType);
                    const recentIds = recentObjects.map(
                        (object: FormConfigData) => object.id,
                    );
                    filter.id = {
                        in: recentIds,
                    };
                }

                setSelectedAdvancedFilters(filter);
                setModelAdvancedSearch(selectedObjectType);
                setIsUsingAdvancedSearch(true);

                try {
                    const gqlType = getTypeByModel(selectedObjectType);
                    const form = getFormConfig(gqlType);

                    const fetchData = await form.functions.get(
                        filter as GraphQLFilter,
                    );

                    const finalData: SearchResult[] = [];
                    const shouldTakeFinalData =
                        newFilters?.includes('location') ||
                        (!newFilters && locationFilterActive);

                    if (shouldTakeFinalData) {
                        const layer =
                            infoToolLayers[
                                form.model as keyof typeof infoToolLayers
                            ];
                        const fetchInfoToolResultsParams = {
                            layers: [layer],
                            searchArea: {
                                type: 'Feature',
                                properties: null,
                                geometry: {
                                    coordinates: [locationFilter?.coordinate],
                                    type: 'Polygon',
                                },
                            } as SearchArea,
                        };
                        const dataInLocation = await fetchInfoToolResults(
                            fetchInfoToolResultsParams,
                        );

                        if (
                            selectedGroups.length === 1 ||
                            newFilters?.length === 1
                        ) {
                            const convertedData: SearchResult[] =
                                dataInLocation?.results.map((item) => {
                                    return {
                                        id: parseInt(item.pk as string, 10),
                                        identification: getLabel(gqlType, item),
                                        location: item.location as string,
                                        modelName: form.model,
                                        angle: item.angle as number,
                                    };
                                });
                            setDataAdvancedSearch?.(convertedData);
                        } else {
                            dataInLocation?.results?.forEach((item) => {
                                const found = fetchData.find(
                                    (data) => data.id === item.pk,
                                );
                                if (found) {
                                    finalData.push({
                                        id: parseInt(item.pk as string, 10),
                                        identification: getLabel(gqlType, item),
                                        location: item.location as string,
                                        modelName: form.model,
                                        angle: item.angle as number,
                                    });
                                }
                            });
                            setDataAdvancedSearch?.(finalData);
                        }
                    } else {
                        const convertedData: SearchResult[] = fetchData.map(
                            (item) => {
                                return {
                                    id: parseInt(item.id as string, 10),
                                    identification: getLabel(gqlType, item),
                                    location: item.location as string,
                                    modelName: form.model,
                                    angle: item.angle as number,
                                };
                            },
                        );
                        setDataAdvancedSearch?.(convertedData);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    onLoading(false);
                    locationFilter?.clearIfLayerExist();
                }
            },
            [
                onLoading,
                setIsAdvancedSearchOpen,
                data,
                selectedGroups,
                searchResults,
                searchFromRecent,
                setSelectedAdvancedFilters,
                setModelAdvancedSearch,
                selectedObjectType,
                setIsUsingAdvancedSearch,
                getRecentObjectsByModel,
                locationFilterActive,
                locationFilter,
                setDataAdvancedSearch,
            ],
        );

        const clearLocationFilter = useCallback(() => {
            locationFilter?.clearIfLayerExist();
            setLocationFilter(null);
        }, [setLocationFilter, locationFilter]);

        const handleClearSelection = useCallback(() => {
            setSelectedGroups([]); // Clear all selections
            clearLocationFilter();
        }, [setSelectedGroups, clearLocationFilter]);

        useEffect(() => {
            if (!isModelSupported(selectedObjectType) && !isMobile)
                handleClearSelection();
        }, [selectedObjectType, handleClearSelection, isMobile]);

        useEffect(() => {
            if (type && data) {
                const selectedType = getType(type, types);
                const field = selectedType.fields.find(
                    (f) => f.name === 'type',
                );
                let quickFilter = <></>;

                const defaultFilter = ['type'];
                const customFilteredConfig = getFilteredConfig(
                    selectedObjectType,
                    defaultFilter,
                    type,
                    getFieldType,
                );

                if (field)
                    quickFilter = (
                        <FormBuilder
                            gqlType={type}
                            data={data}
                            customGroup={customFilteredConfig}
                            onChangeValue={(_name, value) => {
                                if (Array.isArray(value) && value.length >= 1) {
                                    setSelectedGroups(['type']);
                                    submitFunction(['type'], {
                                        type: value,
                                    });
                                }
                            }}
                            returnRawComponents
                        />
                    );

                setQuickFilterComponent(quickFilter);
            }
        }, [
            type,
            types,
            data,
            selectedObjectType,
            setQuickFilterComponent,
            setSelectedGroups,
            submitFunction,
            getFieldType,
        ]);

        const handleCloseAdvancedSearch = () => {
            clearLocationFilter();
            setIsAdvancedSearchOpen(false);
        };

        const renderForm = () => (
            <form id="search-form" action="" onSubmit={handleSubmit}>
                <StyledBody
                    $selectedGroups={selectedGroups.length}
                    $locationFilterActive={locationFilterActive}
                >
                    {type && data && filteredConfig && (
                        <FormBuilder
                            gqlType={type}
                            data={data}
                            customGroup={filteredConfig}
                            onChangeValue={(name, value) => {
                                setData((prev) => {
                                    if (prev) {
                                        return {
                                            ...prev,
                                            [name]: value,
                                        };
                                    }
                                    return prev;
                                });
                            }}
                        />
                    )}
                </StyledBody>
            </form>
        );

        const renderFooterButtons = () => (
            <StyledButtons $isMobile={isMobile}>
                <StyledBtnClearAll
                    label={t('clearAll')}
                    kind="secondary"
                    disabled={selectedGroups.length === 0}
                    onClick={handleClearSelection}
                    data-testid="advanced-search-clear-all"
                />
                {isMobile ? (
                    <StyledBtnApply
                        label={t('apply')}
                        type="submit"
                        form="search-form"
                        disabled={selectedObjectType === ''}
                        data-testid="advanced-search-apply"
                        style={{ flexGrow: 1, maxWidth: '178px' }}
                    />
                ) : (
                    <StyledBtnRight>
                        <StyledBtnCancel
                            label={t('cancel')}
                            kind="secondary"
                            onClick={handleCloseAdvancedSearch}
                            data-testid="advanced-search-cancel"
                        />
                        <StyledBtnApply
                            label={t('apply')}
                            type="submit"
                            form="search-form"
                            disabled={selectedObjectType === ''}
                            data-testid="advanced-search-apply"
                        />
                    </StyledBtnRight>
                )}
            </StyledButtons>
        );

        if (isMobile)
            return (
                <StyledAdvancedSearch
                    $isOpen={isOpen}
                    $isMobile={isMobile}
                    data-testid="advanced-search"
                    ref={advancedSearchRef}
                >
                    <StyledHeader>
                        <StyledHeaderBot data-testid="advanced-search-group-select">
                            <StyledFilterTitle>
                                {t('addFilter')}
                            </StyledFilterTitle>
                            <StyledMobileSelect
                                placeholder={t('advancedSearch.placeholder')}
                                isMultiSelect
                                isFilterable
                                options={options}
                                value={selectedGroups}
                                filterLabels={{
                                    addedFiltersText: t(
                                        'advancedSearch.addedFilters',
                                    ),
                                    allFiltersText: t(
                                        'advancedSearch.allFilters',
                                    ),
                                    clearSelectionText: t(
                                        'advancedSearch.clearSelection',
                                    ),
                                    noResultsFound: t('noSearchResults'),
                                }}
                                onChangeValue={(value) => {
                                    setSelectedGroups(
                                        Array.isArray(value) ? value : [value],
                                    );
                                }}
                                onClearSelection={handleClearSelection}
                                $selectedGroups={selectedGroups.length}
                            />
                        </StyledHeaderBot>
                    </StyledHeader>
                    {renderForm()}
                    {selectedObjectType !== '' && <LocationFilter />}
                    {renderFooterButtons()}
                </StyledAdvancedSearch>
            );

        return (
            <StyledAdvancedSearch
                $isOpen={isOpen}
                $isMobile={isMobile}
                data-testid="advanced-search"
                ref={advancedSearchRef}
            >
                <StyledHeader>
                    <StyledHeaderTop>
                        <StyledHeaderLeft data-testid="advanced-search-model-select">
                            <StyledHeaderTitle>
                                {t('filters')}
                            </StyledHeaderTitle>
                            <StyledHeaderFilter
                                placeholder="Select"
                                options={objectTypes}
                                onChangeValue={(value) => {
                                    if (typeof value === 'string') {
                                        setSelectedObjectType(value);
                                    }
                                }}
                                value={selectedObjectType}
                            />
                        </StyledHeaderLeft>
                        <StyledHeaderRight
                            kind="ghost"
                            data-testid="advanced-search-close"
                            onClick={handleCloseAdvancedSearch}
                        >
                            <Icons.Cross2 />
                        </StyledHeaderRight>
                    </StyledHeaderTop>
                    <StyledDivider />
                    <StyledHeaderBot data-testid="advanced-search-group-select">
                        <StyledFilterTitle>{t('addFilter')}</StyledFilterTitle>
                        <StyledSelect
                            placeholder={t('advancedSearch.placeholder')}
                            isMultiSelect
                            isFilterable
                            options={options}
                            value={selectedGroups}
                            filterLabels={{
                                addedFiltersText: t(
                                    'advancedSearch.addedFilters',
                                ),
                                allFiltersText: t('advancedSearch.allFilters'),
                                clearSelectionText: t(
                                    'advancedSearch.clearSelection',
                                ),
                                noResultsFound: t('noSearchResults'),
                            }}
                            onChangeValue={(value) => {
                                setSelectedGroups(
                                    Array.isArray(value) ? value : [value],
                                );
                            }}
                            onClearSelection={handleClearSelection}
                            $selectedGroups={selectedGroups.length}
                        />
                    </StyledHeaderBot>
                    <StyledDivider />
                </StyledHeader>
                {renderForm()}
                <StyledFooter>
                    <StyledDivider />
                    {selectedObjectType !== '' && <LocationFilter />}
                    <StyledDivider />
                    {renderFooterButtons()}
                </StyledFooter>
            </StyledAdvancedSearch>
        );
    },
);

const StyledAdvancedSearch = styled.div<{
    $isOpen?: boolean;
    $isMobile?: boolean;
}>`
    display: ${(props) => (props.$isOpen ? 'block' : 'none')};
    position: absolute;
    top: 0;
    z-index: 1000;
    width: 348px;
    max-height: calc(100vh - 100px);
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.neutral['50']};
    background-color: ${(props) => props.theme.colors.neutral['10']};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    ${(props) =>
        props.$isMobile &&
        `
        height: 100%;
        width: 100%;
        max-height: 100%;
        border: none;
        box-shadow: none;
        overflow-x: hidden;
        display: ${props.$isOpen && 'flex'};
        flex-direction: column;
        justify-content: space-between;
    `}
`;

const StyledHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const StyledHeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
`;

const StyledHeaderLeft = styled.div`
    display: flex;
    align-items: center;
    padding: 6px 8px;
    gap: 12px;
`;

const StyledHeaderTitle = styled.div`
    ${(props) => props.theme.fonts['16px Bold']}
    color: ${(props) => props.theme.colors.neutral['100']};
`;

const StyledHeaderFilter = styled(Select)`
    font-size: 13px;
    line-height: 16px;
    border-radius: 4px;
    color: ${(props) => props.theme.colors.neutral['90']};
    background-color: ${(props) => props.theme.colors.neutral['40']};
    width: 100px;
    & > button {
        & > div {
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
        }
    }
    & > ul {
        width: 168px;
    }
    & span {
        margin-top: 12px;
        & > svg:first-child {
            display: none;
        }
    }
`;

const StyledHeaderRight = styled(Button)`
    width: 32px;
    height: 32px;
    corlor: ${(props) => props.theme.colors.neutral['90']};
    & > svg {
        width: 16px;
        height: 16px;
    }
`;

const StyledHeaderBot = styled.div`
    padding: 14px 16px;
`;

const StyledFilterTitle = styled.div`
    ${(props) => props.theme.fonts['14px Regular']}
    color: ${(props) => props.theme.colors.neutral['100']};
    padding-bottom: 8px;
`;

const StyledBody = styled.div<{
    $selectedGroups?: number;
    $locationFilterActive?: boolean;
}>`
    display: ${(props) =>
        props.$selectedGroups && props.$selectedGroups > 0 ? 'block' : 'none'};
    max-height: ${(props) => {
        if (props.$locationFilterActive) {
            return 'calc(100vh - 428px)';
        }
        if (props.$selectedGroups && props.$selectedGroups > 5) {
            return 'calc(100vh - 320px)';
        }
        return 'auto';
    }};
    overflow-y: ${(props) =>
        props.$selectedGroups && props.$selectedGroups > 5 ? 'auto' : 'unset'};
    & > div {
        & > div {
            margin-right: 0;
            margin-left: 0;
            & > div {
                border-radius: 0;
                padding-top: 16px;
                & > div:first-child {
                    display: none;
                }
            }
        }
    }
    ${(props) =>
        props.$selectedGroups &&
        props.$selectedGroups == 1 &&
        props.$locationFilterActive &&
        `
        display: none;
    `}
`;

const StyledFooter = styled.div``;

const StyledButtons = styled.div<{ $isMobile?: boolean }>`
    display: flex;
    justify-content: space-between;
    padding: 16px;
    ${(props) =>
        props.$isMobile &&
        `
        position: relative;
        bottom: 0;
    `}
`;

const StyledBtnRight = styled.div`
    display: flex;
    gap: 8px;
`;

const StyledBtnClearAll = styled(Button)`
    background-color: ${(props) => props.theme.colors.neutral['10']};
`;

const StyledBtnCancel = styled(Button)`
    width: 100px;
`;

const StyledBtnApply = styled(Button)`
    width: 100px;
`;

const StyledDivider = styled.div`
    height: 2px;
    background-color: ${(props) => props.theme.colors.neutral['20']};
    width: 100%;
`;

const StyledSelect = styled(Select)<{ $selectedGroups?: number }>`
    & > div > input {
        &::placeholder {
            color: ${(props) => props.theme.colors.neutral['80']};
        }
    }

    // Hide "Added filter" section when none were selected.
    & > ul > div:first-child > div:first-child > div {
        display: ${(props) => (props.$selectedGroups === 0 ? 'none' : 'block')};
    }
`;

const StyledMobileSelect = styled(StyledSelect)`
    ${(props) => props.theme.fonts['14px Regular']}
    line-height: 20px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.neutral['90']};
    & > ul {
        width: 100%;
    }
    & > div:first-child {
        height: 40px;
        display: flex;
        align-items: center;
    }
    & span {
        margin-top: 9px;
        & > svg {
            height: 25px;
            width: 16px;
        }
    }
`;
