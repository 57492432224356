import { createComboField } from '../field-utils';
import { FormGroup } from '../types';
import { WaterDuct } from '@generated';
import { planGroup, addressGroup } from '../groups';

const getLifespan = (data: unknown, _value: unknown, fieldName: string) => {
    const duct = data as WaterDuct;
    const currentYear = new Date().getFullYear();
    const buildYear = duct.buildYear ?? null;

    const currentLifespan = buildYear ? currentYear - buildYear : null;

    if (
        fieldName === 'remainingLifespan' &&
        duct.type?.technicalLifespan &&
        currentLifespan
    ) {
        const remainingLifespan = duct.type.technicalLifespan - currentLifespan;
        return remainingLifespan;
    }

    if (fieldName === 'currentLifespan') {
        return currentLifespan;
    }

    return null;
};

export const ductTechnicalGroup: FormGroup = {
    name: 'technicalInfo',
    fields: [
        createComboField('type.material', 'DUCT_MATERIAL', false, 'material'),
        createComboField('type.diameterId', 'DUCT_DIAMETER', false, 'diameter'),
        createComboField(
            'type.pressureClass',
            'DUCT_PRESSURE_CLASS',
            false,
            'pressure',
        ),
        {
            name: 'type.technicalLifespan',
        },
    ],
};

export const ductLifespanGroup: FormGroup = {
    name: 'lifespan',
    fields: [
        {
            name: 'currentLifespan',
            custom: true,
            component: 'number',
            valueFormatter: getLifespan,
        },
        {
            name: 'remainingLifespan',
            custom: true,
            component: 'number',
            valueFormatter: getLifespan,
        },
    ],
};

export const ductUsageGroup: FormGroup = {
    name: 'usageInformation',
    fields: [
        'buildYear',
        'removalYear',
        createComboField('state', 'DUCT_STATE', false, 'usageState'),
        'length2D',
        'length',
        createComboField('conditionClass', 'FAULT_CODE', false, 'faultCode'),
        createComboField('installLocation', 'DUCT_LOCATION', false, 'location'),
        createComboField('owner', 'DUCT_OWNER'),
        createComboField('ductCategory', 'DUCT_CATEGORY', false, 'category'),
    ],
};

export const ductCommonGroup: FormGroup = {
    name: 'objectData',
    fields: [
        createComboField('locationAccuracy', 'ACCURACY'),
        createComboField('heightAccuracy', 'HEIGHT_ACCURACY'),
        createComboField('mappingMethod', 'SOURCE_CODE'),
        createComboField('safetyDistance', 'SAFETY_DISTANCE'),
        { name: 'surveyNumber', translationKey: 'surveyAmount' },
        createComboField('dateAccuracy', 'DATE_ACCURACY'),
        createComboField('connectionPermission', 'CONNECTION_PERMISSION'),
        'mapper',
        'beginZCoord',
        'endZCoord',
    ],
};

export const notesGroup: FormGroup = {
    name: 'notes',
    fields: [
        {
            name: 'note',
            component: 'textarea',
        },
    ],
};

export const ductGroups = [
    ductTechnicalGroup,
    ductLifespanGroup,
    ductUsageGroup,
    ductCommonGroup,
    planGroup,
    addressGroup,
    notesGroup,
];
