import { useContext, useEffect, useState } from 'react';
import {
    MapContext,
    StateEvent,
    Button,
    Utils,
    DrawShape,
    MEASURE_LAYER,
    useIsMobile,
    Input,
    Icons,
    styled,
} from '@keypro/2nd-xp';
import { Feature } from 'ol';
import Polygon from 'ol/geom/Polygon';
import { Geometry } from 'ol/geom';
import { getArea, getLength } from 'ol/sphere.js';
import { t } from 'i18next';
import { useMobileMenu, useSearchStore } from '@stores';
import { infoToolPolygonHint } from '@components/AppToolbar/Common';

const LOCATION_LAYER = 'location_search';

/**
 * Button to draw and set location filter.
 * @returns {JSX.Element}
 */
export const LocationFilter = ({ ...rest }): JSX.Element => {
    const controller = useContext(MapContext)!;
    const { locationFilter, setLocationFilter } = useSearchStore();
    const { setMenuHeight, setMenuOverlayContent, menuOverlayContent } =
        useMobileMenu();
    const isMobile = useIsMobile();
    const [active, setActive] = useState<boolean>(false);

    useEffect(() => {
        const layerExists = () =>
            controller.layers.findLayerByName(LOCATION_LAYER);

        const createFilterLayer = () => {
            if (!layerExists())
                controller.layers.createVectorLayer(LOCATION_LAYER);
        };

        const onStateChange = (event: StateEvent) => {
            const oldState = event.oldState as DrawShape;
            const newState = event.newState;
            setActive(event.newState?.owner === LocationFilter);
            if (newState?.owner === LocationFilter) {
                createFilterLayer();
                if (controller.layers.findLayerByName(MEASURE_LAYER)) {
                    controller.layers.clearLayer(MEASURE_LAYER);
                    controller.tooltip.hide();
                }
            }

            if (
                newState !== null ||
                oldState?.isDrawing ||
                (layerExists() &&
                    !controller.layers.getFeatures(LOCATION_LAYER).length)
            ) {
                controller?.layers.clearLayer(LOCATION_LAYER);
                controller?.tooltip.hide();
            }
        };

        controller.on('stateChange', onStateChange);

        return () => {
            controller.off('stateChange', onStateChange);
        };
    }, [controller]);

    useEffect(() => {
        // When canceled with the back button in MobileDetailedToolbar
        if (menuOverlayContent === null) controller.state.deactivate();
    }, [menuOverlayContent, controller]);

    const getAreaAndParameter = (geometry: Geometry) => {
        const area = Utils.formatArea(getArea(geometry));
        const perimeter = Utils.formatLength(getLength(geometry));

        return `${t('measureToolTooltipAreaLabel')}: ${area}, ${t('measureToolTooltipPerimeterLabel')}: ${perimeter}`;
    };

    const clearIfLayerExist = () => {
        const layerExists = controller?.layers.findLayerByName(LOCATION_LAYER);
        if (layerExists) {
            controller?.layers.clearLayer(LOCATION_LAYER);
            controller?.tooltip.hide();
        }
    };

    const clearLocationFilter = () => {
        locationFilter?.clearIfLayerExist();
        setLocationFilter(null);
    };

    const setupMobileMenuContent = () => {
        clearLocationFilter();
        setMenuHeight(0);
        setMenuOverlayContent(<DrawConfirm />);
    };

    /**
     * Handle selection event.
     * @param geom Selected geometry
     */
    const onDrawend = (feature: Feature) => {
        const geometry = feature.getGeometry();
        if (!geometry) return;

        const coords = (geometry as Polygon).getCoordinates()[0]; // Get coordinates of the polygon

        const content = getAreaAndParameter(geometry);
        useSearchStore.setState((state) => ({
            selectedGroups: state.selectedGroups.includes('location')
                ? state.selectedGroups
                : [...state.selectedGroups, 'location'],
            locationFilter: {
                coordinate: coords,
                clearIfLayerExist,
                textDescription: content,
            },
        }));

        // Keeping the selected area visible
        controller?.layers.addFeature(LOCATION_LAYER, feature);
    };

    /**
     * Activate the location filter.
     */
    const activate = () => {
        const state = controller.state.activate(
            'drawPolygon',
            LocationFilter,
            infoToolPolygonHint,
        );

        state.on('end', onDrawend).once('deactivate', () => {
            state.off('end', onDrawend);
        });
    };

    /**
     * Toggle location filter on and off.
     */
    const toggle = () => {
        if (!active) {
            activate();
            isMobile && setupMobileMenuContent();
        } else {
            controller.state.deactivate();
        }
    };

    return (
        <StyledGeographicalLocation>
            {t('geographicalLocation')}
            <Input
                inputProps={{
                    value: locationFilter?.textDescription ?? '',
                    placeholder: t('locationPlaceholder'),
                    onChange: () => {}, // manual user input, search with address/area?
                }}
                rightIcon={
                    locationFilter ? (
                        <EndButton clearLocationFilter={clearLocationFilter} />
                    ) : undefined
                }
            />
            <LocationFilterButton
                {...rest}
                onClick={toggle}
                data-testid="advanced-search-location-filter-button"
                label={t('chooseFromMap')}
                kind={active ? 'primary' : 'secondary'}
            />
        </StyledGeographicalLocation>
    );
};

/**
 * The DrawConfirm appears in the mobile MenuOverlay as confirm button for draw shape.
 * @returns JSX.Element
 */
const DrawConfirm = () => {
    const { locationFilter } = useSearchStore();
    const { setMenuOverlayContent, setMenuHeight } = useMobileMenu();

    if (!locationFilter) return <></>;

    return (
        <StyledOverlayContainer>
            <StyledBtnConfirmLocation
                label={t('selectThisPlace')}
                data-testid="advanced-search-location-filter-confirm"
                onClick={() => {
                    setMenuHeight(100);
                    setMenuOverlayContent(null);
                    locationFilter.clearIfLayerExist();
                }}
            />
        </StyledOverlayContainer>
    );
};

interface EndButtonProps {
    clearLocationFilter: () => void;
}
/**
 * The EndButton appears at the end (the right most) of the input field as clear button for selected location.
 * @param clearLocationFilter - The function to handle clearing the selected location (location filter).
 * @returns JSX.Element
 */
const EndButton = ({ clearLocationFilter }: EndButtonProps) => {
    return (
        <StyledEndButtons>
            <StyledCloseButton
                kind="ghost"
                onClick={clearLocationFilter}
                data-testid="clear-location-filter-button"
            >
                <Icons.Cross2 />
            </StyledCloseButton>
        </StyledEndButtons>
    );
};

const LocationFilterButton = styled(Button)`
    width: 100%;
`;

const StyledBtnConfirmLocation = styled(Button)`
    position: absolute;
    top: -86px;
    width: 142px;
    height: 40px;
    background: #1c2023e5;

    & > div {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }
`;

const StyledOverlayContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
`;

const StyledGeographicalLocation = styled.div`
    padding: 16px;
    gap: 8px;
    display: grid;
`;

const StyledEndButtons = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const StyledCloseButton = styled(Button)`
    background-color: ${(props) => props.theme.colors.neutral[50]};
    width: 20px;
    height: 20px;
    border-radius: 50%;

    svg {
        width: 9px;
        height: 9px;

        path {
            stroke-width: 4px;
            color: ${(props) => props.theme.colors.neutral[90]};
        }
    }

    &:hover {
        background-color: ${(props) =>
            props.theme.colors.neutral[60]} !important;
    }
`;
