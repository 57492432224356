import {
    Icons,
    InfoToolMenu,
    MoreMenu,
    MenuDivider,
    MeasureToolMenu,
    styled,
    LocateMeButton,
    useIsMobile,
    DisplayScaleText,
    MapContext,
} from '@keypro/2nd-xp';
import { t } from 'i18next';
import { useMobileMenu, useSearchStore } from '@stores';
import {
    getInfoToolMenuProps,
    getLocateMeButtonProps,
    getMeasureToolMenuProps,
    getMoreMenuProps,
} from './Common';
import { useContext } from 'react';

/**
 * The bottom horizontal floating buttons that contain most of the app's tools in Mobile mode.
 * It also includes the DisplayScaleText.
 * The visibility and position of this component depend entirely on the Mobile MenuHeight.
 */
const MobileToolButtons = (): JSX.Element => {
    const isMobile = useIsMobile();
    const measureToolMenuProps = getMeasureToolMenuProps();
    const moreMenuProps = getMoreMenuProps();
    const infoToolMenuProps = getInfoToolMenuProps();
    const locateMeButtonProps = getLocateMeButtonProps();
    const {
        isMenuOpen,
        menuHeight,
        setInfoToolSelectionMode,
        isDetailedToolbar,
        menuOverlayContent,
    } = useMobileMenu();
    const { isFullsearch, locationFilter } = useSearchStore();
    const controller = useContext(MapContext)!;

    if (!isMobile) return <></>;

    const getDynamicStyles = () => {
        if (!isMenuOpen || menuHeight == 0) {
            return {
                locatedMeBottom: '72px',
                menuToolsBottom: '16px',
                displayScaleBottom: '8px',
                display: 'flex',
            };
        }
        if (menuHeight >= 75) {
            return {
                display: 'none',
            };
        }

        let heightFactor = menuHeight;
        // Detailed and complex toolbar
        if (isDetailedToolbar) {
            heightFactor -= 5;
            if (menuHeight < 50) heightFactor += 7;
            if (menuHeight == 50 && !isFullsearch) heightFactor += 2;
        } else if (menuHeight < 50) {
            heightFactor += 3;
        }
        return {
            locatedMeBottom: `calc(${heightFactor}% + 40px)`,
            menuToolsBottom: `calc(${heightFactor}% - 16px)`,
            displayScaleBottom: `calc(${heightFactor}% - 32px)`,
            display: 'flex',
        };
    };

    const { locatedMeBottom, menuToolsBottom, displayScaleBottom, display } =
        getDynamicStyles();
    return (
        <StyledContainer id="mobile-toolmenu">
            <StyledDisplayScale
                style={{
                    display,
                    bottom: displayScaleBottom,
                }}
                data-testid="displayScaleText"
            >
                <DisplayScaleText />
            </StyledDisplayScale>
            <StyledLocatedMe
                style={{
                    display,
                    bottom: locatedMeBottom,
                }}
            >
                <LocateMeButton {...locateMeButtonProps} />
            </StyledLocatedMe>
            <StyledMenuTools
                $isMenuOpen={isMenuOpen}
                style={{
                    display,
                    bottom: menuToolsBottom,
                }}
                onClick={() => {
                    if (menuOverlayContent !== null) {
                        useMobileMenu.setState(() => ({
                            menuContent: null,
                            menuContentId: '',
                            menuHeight: 0,
                            menuOverlayContent: null,
                            isDetailedToolbar: false,
                            isMenuOpen: false,
                        }));
                        useSearchStore.setState(() => ({
                            isAdvancedSearchOpen: false,
                            isFullsearch: false,
                            isUsingAdvancedSearch: false,
                        }));
                        locationFilter?.clearIfLayerExist();
                        controller.state.deactivate();
                    }
                }}
            >
                <MoreMenu
                    {...moreMenuProps}
                    align="right"
                    buttonIcon={<Icons.MapTools />}
                    upward
                >
                    <InfoToolMenu
                        {...infoToolMenuProps}
                        asMenuItems
                        menuItemsGroupTitle={t('Info')}
                        onChangeMode={(mode) => {
                            setInfoToolSelectionMode(mode);
                        }}
                    />
                    <MenuDivider />
                    <MeasureToolMenu
                        {...measureToolMenuProps}
                        asMenuItems
                        menuItemsGroupTitle={t('measure')}
                    />
                    <MenuDivider />
                </MoreMenu>
            </StyledMenuTools>
        </StyledContainer>
    );
};

export default MobileToolButtons;

interface MobileProps {
    $isMenuOpen?: boolean;
}

const StyledContainer = styled.div`
    z-index: 2;
`;

const StyledLocatedMe = styled.div`
    box-sizing: border-box;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.neutral['20']};
    transition: bottom 250ms;
    right: 16px;
    width: 40px;
    height: 40px;
    overflow: hidden;

    & button {
        width: 40px;
        height: 40px;
    }

    & * > svg {
        width: 20px;
        height: 20px;
    }
`;

const StyledMenuTools = styled.div<MobileProps>`
    box-sizing: border-box;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.accents.blue['10']};
    transition: bottom 250ms;
    right: 16px;
    width: 40px;
    height: 40px;

    & * > button {
        box-shadow: none;
        &:enabled:hover {
            background-color: ${(props) =>
                props.theme.colors.accents.blue['10']};
        }
    }
    & .dropdown > button > svg {
        width: 20px;
        height: 20px;
    }
    // Remove deprecated upward and find a better solution for dropdown, then remove this
    & .dropdown > .dropdown-dropdown {
        ${({ $isMenuOpen }) => $isMenuOpen && 'top: 300px; right: 70px;'};
    }
`;

const StyledDisplayScale = styled.div`
    position: absolute;
`;
